import React, { useState } from "react"
import NewsletterForm from "./NewsletterForm"
import "./Newsletter.scss"
import { useTranslation } from "react-i18next"
import { useOAuthTokens } from "../../hooks/useOAuthTokens"
import BtnRounded from "../Buttons/BtnRounded"
import global from "../../global/global"
import { useAccount } from "../../hooks/useAccount"

export default function Newsletter() {
  const { t } = useTranslation()
  const { accessToken } = useOAuthTokens()
  const { me } = useAccount({})
  const [success, setSuccess] = useState(false)
  const subscribed = me
    ? me.terms
      ? !!me.terms.items.find(term => term.id === process.env.NEWSLETTER_ID)
      : false
    : false

  return (
    <div className="newsletter">
      <div className="newsletter__form">
        <div className="newsletter__form--inner">
          <h3 className="newsletter--title">{t("newsletter.keepYouPosted")}</h3>
          {accessToken ? (
            subscribed || success ? (
              <>
                <p className="newsletter__form--intro">
                  {t("newsletter.updateDescription")}
                </p>
                <BtnRounded fourth route={"/profiel"}>
                  {t("newsletter.update")}
                </BtnRounded>
              </>
            ) : (
              <>
                <p className="newsletter__form--intro">
                  {t("newsletter.description")}
                </p>
                <NewsletterForm onSuccess={() => setSuccess(true)} />
              </>
            )
          ) : (
            <>
              <p className="newsletter__form--intro">
                {t("newsletter.description")}
              </p>
              <BtnRounded
                fourth
                onClick={() => global.modal.setShowAuthModal(true)}
              >
                {t("newsletter.login")}
              </BtnRounded>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
