import React, { useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import TextInput from "../Forms/TextInput"
import BtnForms from "../Buttons/BtnForms"
import { useTranslation } from "react-i18next"
import useForm from "../../hooks/useForm"
import { useGuestApi } from "../../hooks/useApi"
import { useAccount } from "../../hooks/useAccount"

function NewsletterForm({ onSuccess }) {
  const { t } = useTranslation()
  const { addToNewsLetter } = useGuestApi()
  const { me } = useAccount({})
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validation.emailValid"))
      .required(t("validation.emailRequired")),
  })

  const { submit, isLoading, success } = useForm({
    onSubmit: addToNewsLetter,
  })

  const logAnalytics = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "newsletter_subscription"
    })
  }

  useEffect(() => {
    if (success) {
      logAnalytics()
      onSuccess()
    }
  }, [onSuccess, success])

  return (
    <Formik
      initialValues={{ email: me.email }}
      validationSchema={loginSchema}
      onSubmit={submit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form className="form" onSubmit={handleSubmit}>
          <TextInput
            name="email"
            type="email"
            disabled
            placeholder={t("forms.email")}
            className="outline-white"
          />
          <BtnForms
            loading={isLoading}
            _className="btn--newsletter"
            disabled={isSubmitting}
          >
            {t("newsletterForm.subscribe")}
          </BtnForms>
        </form>
      )}
    </Formik>
  )
}

export default NewsletterForm
