import cx from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { breakpoints } from "../../constants/breakpoints"
import { HERO_SOCIALS } from "../../fixtures/SocialsData"
import { useGuestApi } from "../../hooks/useApi"
import NowPlaying from "../NowPlaying/NowPlaying"
import Icon from "./../Icon/Icon"
import { useWindowSize } from "../../hooks/useWindowSize"
import "./Hero.scss"

const Hero = ({className}) => {
  const { getMainHeader } = useGuestApi()
  const [header, setHeader] = useState(null);

  useEffect(() => {
    async function fetchMainHeader() {
      try {
        await getMainHeader().then(header => {
          setHeader(header ?? null);
        })
      } catch(e) {
        setHeader(null)
      }
    }
    fetchMainHeader()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMainHeader() {
      try {
        await getMainHeader().then(header => {
          setHeader(header ?? null);
        })
      } catch(e) {
        setHeader(null)
      }
    }
    const interval = setInterval(() => {
      fetchMainHeader()
    }, 60000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { width } = useWindowSize()

  const isDesktop = useMemo(() => width >= breakpoints.large, [width])

  return (
    <header
      className={cx("hero", className)}
    >
      <div
        className={cx("hero__content", {
          "container": isDesktop,
        })}
      >
        {header?.titleImage &&
          <h2 className={cx("hero__title", {
            'container': !isDesktop
          })}>
            <span className="u-visually-hidden">{header?.title}</span>
            <img src={header?.titleImage} alt={header?.title} />
          </h2>
        }
        <div className="hero__presenter">
          <img src={header?.primaryImage} alt={header?.subtitle} />
        </div>
        <div className="hero__radio-player">
          <NowPlaying />
        </div>
        <ul className={cx("hero__socials", {
          'container': !isDesktop
        })}>
          {HERO_SOCIALS.map(({ url, icon, title }) => (
            <li className="hero__socials-item" key={title}>
              <a
                className="hero__socials-item-link"
                href={url}
                target="_blank"
                rel="noreferrer"
                title={title}
              >
                <Icon className="hero__socials-item-icon" name={icon} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </header>
  )
}

export default Hero
