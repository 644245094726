import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { formatPageInformation } from "../../helpers/Meta"
import { getImageType } from "../../helpers/Prepr"
import {
  convertToSlug,
  convertToSlugWithOrigin,
  getColor,
  getInstagramImage,
  getModelType,
  getSingleImage,
  getTitle,
} from "../../helpers/Publications"
import { useGuestApi } from "../../hooks/useApi"
import { useOAuthTokens } from "../../hooks/useOAuthTokens"
import Card from "../Card/Card"
import LoadingCard from "../Card/LoadingCard"
import HomeModal from "../Modal/HomeModal"
import "./Grid.scss"
import BtnRounded from "../Buttons/BtnRounded"
import Icon from "../Icon/Icon"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 10,
}

export default function Grid({ data, history }) {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalClosing, setModalClosing] = useState(false)
  const [modalData, setModalData] = useState([])
  const [gridItems, setGridItems] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(initialPagination);
  const [page, setPage] = useState(1);

  const { accessToken, forgetOAuthTokens } = useOAuthTokens()
  const { t } = useTranslation()
  const { getHomePageGridPublications, setPageInformation } = useGuestApi()

  useEffect(fetchPublications, [page])

  function fetchPublications() {
    getHomePageGridPublications(pagination).then(response => {
      if (response?.status === 401 && accessToken) {
        forgetOAuthTokens()
        window.location.reload()
      } else {
        setPagination({
          total: response.total,
          limit: response.limit,
          skip: response.skip
        });
        setGridItems(
          gridItems.concat(
            response?.grid?.items.length > 0 ? response.grid.items : []
          )
        )
      }
    })
  }

  const openModal = data => {
    const newUrl = convertToSlugWithOrigin(data.model_id, data.slug)
    window.history.pushState({ path: newUrl }, "", newUrl)
    document.body.classList.add("modal-active")
    setModalVisible(!modalVisible)
    setPageInformation(formatPageInformation(data))
    setModalData(data)
  }

  const closeModal = () => {
    document.body.classList.remove("modal-active")
    window.history.pushState("", "", history.pathname)
    setModalClosing(true)
    setTimeout(() => {
      setModalVisible(!modalVisible)
      setModalClosing(!setModalClosing)
    }, 300)
  }

  function loadMore () {
    setIsLoading(true)
    if(gridItems.length < pagination.total) {
      setPagination({
        total: pagination.total,
        limit: 12,
        skip: gridItems.length
      });
      setPage(page + 1)
      setIsLoading(false)
    }
  }

  return (
    <>
      {!gridItems.length && (
        <>
          {[...Array(initialPagination.limit)].map((x, i) => (
            <LoadingCard key={i} />
          ))}
        </>
      )}
      {gridItems.map((item, index) => (
        <Card
          key={index}
          type={getModelType(item.model_id)}
          title={getTitle(item.title)}
          image={getSingleImage(
            getImageType(
              "image_website_block",
              item.image,
              null,
              item.image_website_block,
              null
            )
          )}
          instagramImage={getInstagramImage(item.instagram_post)}
          color={getColor(item.color)}
          route={convertToSlug(item.model_id, item.slug)}
          onClickCard={() => openModal(item)}
        />
      ))}
      <div className="grid--more">
        {gridItems.length < pagination.total &&
        <BtnRounded
          onClick={loadMore}
          fifth
          large
          loading={isLoading}
        >{t('list.loadMore')}</BtnRounded>
        }
      </div>
      <HomeModal
        animating={modalClosing}
        visible={modalVisible}
        onClickClose={closeModal}
        data={modalData}
      />
    </>
  )
}
