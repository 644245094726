import { useLocation } from "@reach/router"
import React from "react"
import { stationsData } from "src/constants/Stations"
import ChannelsCarousel from '../components/Carousel/ChannelsCarousel'
import ProgramsCarousel from "../components/Carousel/ProgramsCarousel"
import Grid from "../components/Grid/Grid"
import Hero from "../components/Hero/Hero"
import HomeContainer from "../components/Layout/HomeContainer"
import Newsletter from "../components/Newsletter/Newsletter"
import "./styles/_fonts.scss"
import "./styles/index.scss"


function Home() {
  const history = useLocation()

  return (
    <HomeContainer page="Home" variant='no-padding'>
      <Hero />
      <div className="home container">
        <ChannelsCarousel data={stationsData} />
        <div className="home__grid">
          <Newsletter />
          <Grid history={history} />
        </div>
        <ProgramsCarousel />
      </div>
    </HomeContainer>
  )
}

export default Home
