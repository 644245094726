export const stationsData = [
  {
    id: "5100",
    apiId: 9,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWHATAFEELING.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie.jpg`,
    title: "nostalgie",
    name: "nostalgie",
    color: "#f7941d",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5104",
    apiId: 12,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAF6070.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-plus.jpg`,
    title: "Nostalgie+",
    name: "nostalgie-plus",
    color: "#da2957",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-plus`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5111",
    apiId: 8,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAFEXTRA.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-top-3000.png`,
    title: "Extra Top 3000",
    name: "nostalgie-extra",
    color: "#fece7a",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-extra`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5110",
    apiId: 10,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAFNL.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-nl.jpg`,
    title: "Extra Vlaams",
    name: "nostalgie-extra-vlaams",
    color: "#fece7a",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-extra-vlaams`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5102",
    apiId: 11,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAF80.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-80.jpg`,
    title: "80",
    name: "nostalgie-80",
    color: "#2d5674",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-80`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5103",
    apiId: 4,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAF90.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-fr.jpg`,
    title: "FR",
    name: "nostalgie-fr",
    color: "#78cdd0",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-fr`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5108",
    apiId: 5,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAFPARTY.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-disco.jpg`,
    title: "Disco",
    name: "nostalgie-disco",
    color: "#f48a72",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-disco`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5109",
    apiId: 7,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAFRELAX.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-relax.jpg`,
    title: "Relax",
    name: "nostalgie-relax",
    color: "#53bd95",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-relax`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5106",
    apiId: 6,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAFROCKS.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-rock.jpg`,
    title: "Rock",
    name: "nostalgie-rock",
    color: "#da2957",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-rock`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
  {
    id: "5107",
    apiId: 3,
    fallback:
      "https://playerservices.streamtheworld.com/api/livestream-redirect/NOSTALGIEWAFNEWWAVE.mp3?dist=nl_nos_be_website",
    logo: `${process.env.WEBSITE_URL}/luister/images/nostalgie-new-wave.jpg`,
    title: "New wave",
    name: "nostalgie-new-wave",
    color: "#2d5674",
    url: `${process.env.WEBSITE_URL}/luister/nostalgie-new-wave`,
    stationUrl: `${process.env.WEBSITE_URL}`,
  },
]
