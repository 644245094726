import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGuestApi } from "../../hooks/useApi"
import Icon from "../Icon/Icon"
import "./NowPlaying.scss"
import { getRadioTracksEndpoint } from "../../services/api"
import { get } from "axios"

const NowPlaying = () => {
  const [radio] = useState(9)
  const { t } = useTranslation()
  const { currentTrack, setTrack } = useGuestApi()

  useEffect(() => {
    async function fetchTracks(radioId) {
      try {
        await get(getRadioTracksEndpoint(radioId)).then(response => {
          setTrack(response.data.data[0])
        })
      } catch(e) {
        setTrack(null)
      }
    }
    fetchTracks(radio)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchTracks(radioId) {
      try {
        await get(getRadioTracksEndpoint(radioId)).then(response => {
          setTrack(response.data.data[0])
        })
      } catch(e) {
        setTrack(null)
      }
    }
    const interval = setInterval(() => {
      fetchTracks(radio)
    }, 60000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const openPlayer = () => {
    const url = "/luister/playnostalgie"
    window.open(url, "_blank", "width=420,height=662")
  }

  if (!currentTrack) {
    return null;
  }

  return (
    <div className="now-playing">
      <div className="now-playing__cover">
        <img
          className="now-playing__cover-img"
          src={currentTrack.cover_art}
          alt={`${currentTrack.title} by ${currentTrack.artist} album cover`}
        />
      </div>
      <div className="now-playing__content">
        <div className="now-playing__track">
          <p className="now-playing__track-title">{currentTrack.title}</p>
          <p className="now-playing__track-artist">{currentTrack.artist}</p>
        </div>
        <button onClick={openPlayer} className="now-playing__button">
          <Icon className="hero__socials-item-icon" name="play" />
          <span>{t("player.listenNow")}</span>
        </button>
      </div>
    </div>
  )
}

export default NowPlaying
